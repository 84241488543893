html {
  font-size: 14px;

  @media (max-width: 767.98px) {
    font-size: 16px;

    @media print {
      font-size: 14px;
    }
  }
}

body {
  background: $custom-white;
}

// Text color
.text-primary {
  color: $brand-primary !important;
}

.text-secondary {
  color: $brand-secondary !important;
}

.text-secondary-dark {
  color: $brand-secondary-dark !important;
}

.text-dark {
  color: $brand-dark !important;
}

// Btn and btn color
.btn {
  border-radius: 0.125rem;
}

.bg-primary {
  background-color: $brand-primary !important;
}

.bg-primary.bg-soft {
  background-color: $brand-primary-light !important;
}

.bg-secondary {
  background-color: $brand-secondary !important;
}

.bg-secondary.bg-soft {
  background-color: $brand-secondary-light !important;
}

.bg-neutral {
  background-color: $brand-neutral !important;
}

.bg-light {
  background-color: $brand-light !important;
}

.bg-dark {
  background-color: $brand-dark !important;
}

//Links
.link-primary {
  color: $brand-dark;

  a:hover {
    color: $brand-primary;
  }
}

.link-secondary {
  color: $brand-dark;

  a:hover {
    color: $brand-secondary;
  }
}

.table-border {
  border-color: $brand-neutral !important;
}

.table-head-bg {
  background-color: $brand-dark;
}

.badge.bg-primary-green[href]:hover,
.badge.bg-primary-green[href]:focus {
  background-color: $brand-secondary !important;
}

// Checkbox
.form-check-secondary .form-check-input:checked {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
}

.form-check-input:checked {
  &.form-check-secondary {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  }
}

.form-checkbox-control {
  margin-top: calc(0.5rem + 21px);
  display: flex;
  height: 36px;
  align-items: center;

  @media (max-width: 767.98px) {
    margin-top: calc(0.5rem + 24px);
    height: 41px;
  }
}

.form-check {
  &_size_lg {
    padding-left: 2em;

    .form-check-input {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: -2em;
      margin-top: 0;
    }
  }
}

.form-checkbox-group {
  &_horizontal {
    .form-check:not(:first-of-type) {
      margin-left: 1.25rem;
    }
  }
}

// Radio btn
.form-radio-secondary .form-check-input:checked {
  border-color: $brand-secondary;
  background-color: $brand-secondary;
}

.form-radio-secondary .form-check-input:checked:after {
  color: $brand-secondary;
}

// Input - right accessory
.form-control.form-control-right-accessory {
  padding-right: 2.75rem;
  background-position: right 2.75rem center;
}

.was-validated .form-control:invalid,
.form-control.form-control-right-accessory.is-invalid {
  padding-right: $form-select-feedback-icon-padding-end;
  background-position: right 2.75rem center;
}

// Input - left accessory
.form-control.form-control-left-accessory {
  padding-left: 2rem;
}

.was-validated .form-control:invalid,
.form-control.form-control-left-accessory.is-invalid {
  //padding-left: $form-select-feedback-icon-padding-end;
}

.form-right-accessory {
  position: absolute;
  top: 0;
  right: 0.5rem;
  z-index: 3;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-left-accessory {
  position: absolute;
  top: 0;
  left: 0.5rem;
  z-index: 4;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-control-eye {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.form-control-border-right-round {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.form-control-border-left-round {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.form-action-button-block {
  margin-top: calc(0.5rem + 21px);
  display: flex;
  height: 36px;
  align-items: center;

  @media (max-width: 767.98px) {
    margin-top: calc(0.5rem + 24px);
    height: 41px;
  }
}

// Spacing
.mr-2 {
  margin-right: 0.5rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

a[disabled] {
  pointer-events: none;
}

// Loading screen spinner
.chase-dot {
  &:before {
    background-color: $brand-secondary;
  }
}

// Avatar
.img-cover {
  object-fit: cover;
}

.avatar-xs {
  height: 32px;
  width: 32px;
}

// Page title
.page-title-box {
  min-height: 36px;
}

.page-title-box h4,
.page-title-box .h4 {
  text-transform: none;
}

// Avatar upload
.avatar-select-wrap {
  position: relative;
}

.avatar-label {
  margin: 0;
  cursor: pointer;
}

.avatar-loading {
  background-color: $custom-gray;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Card color
.card-header-dark-green-bg {
  background: $brand-dark;
  color: $white;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

// Dashboard page header
.page-header {
  &__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
  }

  &__right {
    @media (max-width: 575.98px) {
      margin: 1rem 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}

// Modal
.modal-block {
  .modal-content {
    background-color: $brand-neutral;
    border-color: $brand-neutral;
  }

  .modal-header {
    border-color: transparent;
  }

  .modal-footer {
    border-color: transparent;
  }
}

// Table loader
.table-wrap {
  position: relative;
}

.table-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  top: 0;
  padding-top: 32px;
  background: rgba(255, 255, 255, 0.3);
}

// PAGINATION
.pagination {
  user-select: none;
}

.pagination_theme_primary {
  .page-link {
    user-select: none;
  }

  .page-link:hover {
    color: $custom-white;
    background-color: $brand-primary-light;
  }

  .page-link:focus {
    color: $brand-primary;
  }

  .page-item.active .page-link {
    color: $white;
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
}

// Confirm popup
.modal-confirm-popup {
  max-width: 550px;

  .modal-body {
    padding-top: 0;
  }
}

// Avatar
.card-user-photo {
  position: relative;
  margin-right: 1rem;

  @media (max-width: 575.98px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
    object-position: top;

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }
}

.user-photo-avatar__block {
  display: flex;
  width: 160px;
  height: 240px;

  @media (max-width: 575.98px) {
    width: 100%;
    height: auto;
  }
}

.user-photo-avatar__edit-block {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.card-logo-photo {
  position: relative;
  margin-right: 1rem;

  @media (max-width: 575.98px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  &__block {
    display: flex;
    width: 105px;
    height: 105px;

    @media (max-width: 575.98px) {
    }
  }

  &__placeholder-block {
    background-color: $brand-neutral;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 64px;
      height: 64px;
    }
  }
}

// Info list
.info-list {
  &__block {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    margin: 0.25rem 0;
  }

  &__label {
    font-size: 1rem;
    font-weight: 700;
    margin-right: 0.25rem;
  }

  &__info {
    font-size: 1rem;
  }
}

// Badge town
.badge-town {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $custom-white;
  white-space: nowrap;
  border-radius: 0.125rem;
  width: 26px;
  height: 26px;
  border: 1px solid $custom-white;
  text-transform: uppercase;
  font-size: 10px;
}

// React-select
.select2-container {
  .select2-selection {
    &__control {
      min-height: calc(1.5rem + 0.5rem + 8px) !important;

      // hotfix
      @media (max-width: 767.98px) {
        min-height: calc(1.5rem + 0.5rem + 9px) !important;
      }
    }

    &__value-container {
    }

    &__indicator {
      padding: 0 8px !important;
    }

    &__menu {
      z-index: 5;
    }
  }

  .is-invalid .select2-selection__control {
    border-color: $brand-danger !important;
  }
}

// Overlay loading
.overlay-block {
  position: relative;
  height: 100%;

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    top: 0;
    background: rgba(255, 255, 255, 0.3);
  }
}

// Page content with right sidebar
.page-content {
  background-color: white !important;

  &.page-content_sidebar {
    padding: calc(#{$header-height} + #{$grid-gutter-width}) 0 $footer-height 0;

    .page-content__container {
      padding: 0 calc(#{$grid-gutter-width} / 2);
      margin-right: $panel-sidebar-width;

      @media (max-width: 1199.98px) {
        margin-right: 0;
      }
    }

    .page-content__sidebar {
      width: $panel-sidebar-width;
      z-index: 1001;
      //background: $panel-sidebar-bg;
      bottom: 0;
      margin-top: 0;
      position: fixed;
      top: 0;
      padding-top: $header-height;
      right: 0;

      @media (max-width: 1199.98px) {
        width: 0;
      }
    }
  }
}

.trial-alert_enabled {
  .page-content_sidebar {
    .page-content__sidebar {
      padding-top: calc(#{$header-height} + #{$trial-alert-height});
    }
  }
}

.divider {
  background-color: $custom-gray-300;
  opacity: 1;
}

// Checkbox group
.form-checkbox-group {
  padding: 0;
  border-width: 0;
  flex-wrap: wrap;
  background-color: transparent;
}

// Social networks block
.social-network-overview {
  max-width: calc(124px + (2 * 6px));
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;

  &__button-wrap {
    padding: 6px 10px;
  }
}

// Tooltip
.tooltip {
  &.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
  }

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $brand-primary;
  }

  .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }

  &.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }

  .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  &.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: $brand-primary;
  }
}

.mark-dot-pointer {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: $custom-gray-400;
  border-radius: 50%;

  &.pos-t_d_2 {
    position: relative;
    top: -2px;
  }
}

.text-gray {
  color: $custom-gray !important;
  vertical-align: middle;
}

.float-left {
  float: left;
}

// TABLE
.table-cell_p-v_sm {
  td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.table-column-sticky_end {
  position: sticky;
  right: -1px;
}

.attached-file-card {
  &__icon-block {
    height: 3rem;
    width: 3rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    display: flex;
  }
}

// File picker button
.files-picker-action {
  margin: 1rem 0;

  &__btn {
    font-weight: 500;
    color: $brand-dark;

    &:hover,
    &:focus {
      color: $brand-dark;
    }
  }

  &__btn-icon {
    padding-right: 1rem;
  }
}

// Iframe video
.iframe-video-player {
  background: $brand-dark;
}

// React-datepicker
.react-datepicker-popper {
  z-index: 11 !important;
}

// Marks
.checkbox-mark {
  width: 22px;
  height: 22px;

  &_size_sm {
    width: 18px;
    height: 18px;
  }
}

// Controls
.selection-control {
  margin: 0;
  cursor: pointer;
}

// Sidebar content
.panel-common-sidebar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-height});
}

.trial-alert_enabled {
  .panel-common-sidebar-content {
    height: calc(100vh - #{$header-height} - #{$trial-alert-height});
  }
}

.drawer {
  .panel-common-sidebar-content {
    height: calc(100vh - 48px); // drawer header height
  }
}

//analytics
//radio buttons in charts
.chart-controller {
  .form-radio-outline,
  .form-radio-success {
    .form-check-input {
      &:checked {
        border-color: $brand-secondary;
        background-color: $brand-secondary;

        &:after {
          background-color: $brand-secondary;
          top: 4px !important;
          left: 4px;
          right: 4px;
          width: 4px;
          height: 4px;
        }
      }
    }
  }
}

//analytics charts
.apexcharts-legend-text {
  font-size: 1rem !important;
}

//analytics charts -- circle chart height
.circle-height {
  height: calc(100% - 24px);
}

.circle-chart-title {
  max-width: 80px;
}

// Col divider
.col-divider {
  &_start {
    border-top: 1px solid #d1dad9;
    margin-top: -1px;

    &.col-divider_md {
      @media (min-width: 768px) {
        border: none;
        margin-top: initial;
        border-left: 1px solid #d1dad9;
        margin-left: -1px;
      }
    }
  }
}

.field-error-message {
  color: $brand-danger;
  font-size: 80%; //like in styles of invalid-feedback
}

//models general logistics forms
//travel form
.expenses-container {
  //background-color: #eaf2f2;
  //padding: 20px;
  min-height: 100px;
  font-size: 15px;

  @media (max-width: 767.98px) {
    font-size: 17px;
  }
}

.expense-border-bottom {
  border-bottom: 1px solid #d7dfde;
}

.table-border-bottom {
  border-bottom: 1px solid #d7dfde;
}

// FullCalendar
.fc {
  .fc-toolbar-chunk .btn-primary {
    border-radius: 0.125rem;
    background-color: $brand-dark;
    border-color: $brand-dark;

    &:hover {
      background-color: $custom-blue-gray-700;
      border-color: $custom-blue-gray-700;
    }

    &.active {
      background-color: $custom-blue-gray-700;
      border-color: $custom-blue-gray-700;
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem $custom-blue-gray-200;
    }
  }

  .fc-daygrid-event {
    padding: 2px;
    font-size: 14px;

    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }
}

.location-form-tabs-nav.tabs-nav.nav-pills {
  .nav-item:not(:first-of-type) {
    margin-left: 0.5rem;
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    width: 26px;
    height: 26px;
  }
}

//ICON
.icon-base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  font-size: 16px;
  border-radius: 50%;

  &_bg_soft-danger {
    background-color: #f9d1c9;
  }
}

.form-control-offset {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

//TextButton
.text-button {
  background: none;
  border: none;
  cursor: pointer;

  &_primary {
    color: $brand-primary;

    &:hover {
      color: $brand-primary-dark;
    }

    &:disabled {
      color: $brand-primary-light;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &_secondary {
    color: $brand-secondary;

    &:hover {
      color: $brand-secondary-dark;
    }

    &:disabled {
      color: $brand-secondary-light;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &_danger {
    color: $brand-danger;

    &:hover {
      color: $brand-danger-dark;
    }

    &:disabled {
      color: $brand-danger-light;
    }

    &:focus {
      text-decoration: underline;
    }
  }
}

// Attachment list control
.attachment-list-control {
  .files-uploader-field__file-col {
    width: 100%;
  }
}

// Global Print
@media print {
  .panel-topbar__nav {
    display: none !important;
  }

  .page-content.page-content_sidebar {
    padding: 0;
  }

  .page-content.page-content_sidebar .page-content__container {
    padding: 0;
    margin: 0;
  }

  .page-content__sidebar {
    display: none;
  }

  // Iubenda (cookie)
  .iubenda-tp-btn {
    opacity: 0 !important;
  }
}

//Count Badge
.count-badge {
  display: flex;

  &__wrapper {
    position: relative;
  }

  &__content {
    position: absolute;
    left: -20px;
    top: -5px;
    min-width: 15px;
    min-height: 10px;
    line-height: 10px;
    padding: 5px;
    font-size: 10px;
    border-radius: 20px;
    text-align: center;

    &_primary {
      background: $brand-primary;
      color: $custom-white;
    }

    &_secondary {
      background: $brand-secondary;
      color: $custom-white;
    }
  }
}
