.info-card {
  background: $brand-neutral;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &__title-block {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1rem;

    @media (max-width: 767.98px) {
      font-size: 18px;
    }
  }

  &__title {
    display: flex;
  }

  &__add-btn {
    font-size: 1rem;
    font-weight: 400;
    margin-left: 0.25rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &_loader {
    .info-card__content {
      min-height: 30px;
    }
  }
}
