// Sidebar theme
body[data-sidebar="primary-green"] {
  .navbar-brand-box {
    background: $brand-neutral;

    display: flex;
    align-items: center;
    justify-content: center;
    height: $navbar-brand-box-height;
    @media (max-width: 991.98px) {
      height: $header-height;
    }

    .logo-dark {
      display: none;
    }

    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $brand-secondary-dark !important;
    background-color: $white !important;
    font-weight: bold;

    > a {
      color: $brand-secondary-dark !important;

      i {
        color: $brand-secondary-dark !important;
      }
    }

    > i,
    .active {
      color: $brand-secondary-dark !important;
    }
  }

  #sidebar-menu {
    ul {
      li {
        &.menu-title {
          color: $brand-secondary;
        }

        a {
          font-size: 1rem;
          color: $brand-secondary;

          @media (min-width: 768px) {
            padding: 0.825rem 1.725rem;
          }

          i {
            color: $brand-secondary;

            @media (min-width: 768px) {
              min-width: 2.285rem;
              font-size: 1.428rem;
            }
          }

          &.waves-effect {
            .waves-ripple {
              background: $brand-secondary;
            }
          }

          &:hover {
            color: $brand-secondary-dark;

            i {
              color: $brand-secondary-dark;
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: $brand-secondary;

              &:hover {
                color: $brand-secondary-dark;
              }
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .vertical-menu {
      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              color: $white;

              i {
                color: $white;
              }
            }

            i {
              font-size: 1.657rem;
            }
          }
        }

        ul {
          li {
            &.mm-active {
              .active {
                color: $white !important;
              }
            }

            ul.sub-menu {
              li {
                a {
                  &:hover {
                    color: $white;
                  }
                }

                &.mm-active {
                  color: $white !important;

                  > a {
                    color: $white !important;

                    i {
                      color: $white !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

body[data-sidebar="primary-green"] {
  .vertical-menu {
    //background-image: linear-gradient(to right, #556ee6, #34c38f);
    background: $brand-neutral;

    padding-top: $navbar-brand-box-height;
    @media (max-width: 991.98px) {
      padding-top: 0;
    }
  }

  &.vertical-collpsed {
    .vertical-menu {
      padding-top: $header-height;
      @media (max-width: 991.98px) {
        padding-top: 0;
      }

      .navbar-brand-box {
        //background-image: linear-gradient(to right, #556ee6, #34c38f);
        background: $brand-neutral;
        height: $header-height;
      }

      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              background: $brand-secondary-dark;

              i {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }

  &[data-sidebar-size="small"] {
    .menu-title {
      //background-image: linear-gradient(to right, #556ee6, #34c38f);
      background: $brand-secondary;
    }
  }
}
