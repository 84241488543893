.avatar-wrap {
  overflow: hidden;
  display: flex !important;

  img {
    width: 100%;
  }
}

$base-color: #ddd;
$shine-color: #e8e8e8;

.avatar-wrap {
  &.lazy-load-image-background.skeleton {
    background: linear-gradient(
      90deg,
      $base-color 0px,
      $shine-color 40px,
      $base-color 80px
    );
    background-size: 200%;
    animation: loader 1s infinite reverse;
  }

  &.lazy-load-image-background.skeleton.lazy-load-image-loaded {
    background: none;
  }

  &.lazy-load-image-background.skeleton > img {
    opacity: 0;
  }

  &.lazy-load-image-background.skeleton.lazy-load-image-loaded > img {
    opacity: 1;
    transition: opacity 0.3s;
  }
}

/* skeleton animation */
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

.avatar-rounded {
  position: relative;

  &__outline {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px $brand-secondary;
  }

  .avatar-wrap {
    border-radius: 50%;
    height: 44px;
    width: 44px;
  }

  &__without-crop {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
