.gender-tabs-nav {
  border-bottom: none;

  .nav-item {
    position: relative;
    color: $brand-secondary;
    margin-right: 0.5rem;

    .nav-link {
      border: none;
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus {
        color: $brand-secondary;
      }

      &::after {
        content: "";
        background: $brand-secondary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $brand-secondary;
        background-color: transparent;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}
