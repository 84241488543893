.popover-block {
  overflow: hidden;
  &__body {
    background-color: $custom-white;
    border-color: $custom-white;
    font-size: small;
    -webkit-box-shadow: 6px 9px 19px 5px rgba(34, 60, 80, 0.24);
    -moz-box-shadow: 6px 9px 19px 5px rgba(34, 60, 80, 0.24);
    box-shadow: 6px 9px 19px 5px rgba(34, 60, 80, 0.24);
  }

  &__header {
    background-color: $brand-primary;
    color: $custom-white;
    font-size: medium;
  }
}
