.sidebar-drawer {
  &.drawer {
    z-index: 1050;
  }

  .drawer-content-wrapper {
    width: $sidebar-width;
    background-color: $brand-neutral;
  }

  .drawer-body {
    padding: 0;
  }

  .sidebar-drawer-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$header-height} - 8px);
  }
}

.vertical-collpsed .sidebar-drawer {
  .navbar-brand-box {
    @media (max-width: 991.98px) {
      width: auto !important;
    }
  }
}
