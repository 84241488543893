// Variables

// Color system

$primary-green: #4c9f99;
$bg-light-green: #f8fdfd;
$dark-green: #1b3937;
$sidebar-dark-green-bg: #1b3937;
$btn-bg-light-green: #d1ebd0;
$icon-bg-dark-green: #01636f;
$panel-sidebar-bg: #eaf2f2;
$text-gray: #777777;
$lavender-color: #d4d9ff;
$moccasin-color: #ffefb5;
$dark-slate-gray: #303030;
$lavender-color-2: #deeaea;
$gainsboro-color: #dddddd;
$dimgray-color: #555555;
$mediumseagreen-color: #53c36c;
$plum-color: #ea95cd;
$green-gray-color: #83aa5d;
$cornflowerblue-color: #4c99e5;
$palegoldenrod-color: #ececaf;
$tomato-color: #ec5353;

$panel-sidebar-width: 280px;

$tooltip-bg-primary-green: $primary-green;

// scss-docs-start colors-map
$giulia-colors: (
  "primary-green": $primary-green,
);

@import "project-colors.module";

//BRAND COLORS
//$brand-primary: $custom-red;
//$brand-primary-light: $custom-red-300;
//$brand-primary-dark: $custom-red-600;

$brand-primary: $custom-sky;
$brand-primary-light: $custom-sky-50;
$brand-primary-dark: $custom-sky-800;

$brand-secondary: $custom-sky;
$brand-secondary-light: $custom-sky-50;
$brand-secondary-dark: $custom-sky-800;

$brand-success: $custom-green;

$brand-info: $custom-cyan;

$brand-warning: $custom-yellow;

$brand-danger: $custom-red;
$brand-danger-light: $custom-red-200;
$brand-danger-dark: $custom-red-600;

$brand-pink: $custom-pink;

$brand-light: $custom-gray-200;

$brand-dark: $custom-blue-gray-800;

$brand-neutral: $custom-blue-gray-50;

$facebook-brand-color: #4267b2;

// Layouts config
$navbar-brand-box-height: 130px;
$trial-alert-height: 40px;

$event-data-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
