.panel-sidebar {
  height: 100%;

  &__sidebar {
    width: 100%; // $panel-sidebar-width;
    height: 100%;
    position: relative;
    background-color: $brand-neutral;

    @media (max-width: 1199.98px) {
      display: none;
    }
  }

  &__sidebar-content {
    width: 100%;
    padding: $grid-gutter-width 1.75rem 1.5rem 1.5rem;
  }
}

.panel-sidebar-drawer {
  @media print {
    display: none;
  }

  .drawer-content-wrapper {
    width: $panel-sidebar-width;
    background-color: $brand-neutral;
  }

  .drawer-handle {
    background-color: $brand-neutral;
  }

  &.drawer-open {
    .drawer-handle {
      box-shadow: -4px 0 4px rgba(0, 0, 0, 0.05);
    }
  }

  .drawer-header {
  }

  .panel-sidebar__sidebar-content {
    padding-top: 0;
  }

  .drawer-body {
    padding: 0;
  }
}

.panel-filters-sidebar {
  .panel-filters-content {
  }

  &.drawer {
    .panel-filters-content {
    }
  }

  .form-checkbox-group {
    .form-check:last-of-type {
      margin-left: 1.25rem;
    }
  }
}
