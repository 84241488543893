.rating-badge {
  &_size_lg {
    width: 60px;
    height: 60px;
  }

  &_size_md {
    width: 30px;
    height: 30px;
  }

  &_size_sm {
    width: 22px;
    height: 22px;
  }

  &_size-xs {
  }
}
